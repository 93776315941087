import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="Iconic — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>
		<header>
			<h1>Iconic. Interactive icons made for the modern web.</h1>
			<h3>
				Acquired by InVision &mdash; Co-Founder & Director of Product Design
			</h3>
			<p>
				Born on{" "}
				<a
					href="https://www.kickstarter.com/projects/207474036/iconic-advanced-icons-for-the-modern-web/community"
					target="_blank"
					rel="noopener noreferrer"
				>
					Kickstarter
				</a>{" "}
				from a frustration with the static visual systems found in modern web
				products,{" "}
				<a
					href="http://useiconic.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					Iconic
				</a>{" "}
				expands what's possible with the powerful visual medium of iconography.
			</p>
			<p>
				Each smart icon has a super-simple API which makes swapping out assets a
				thing of the past. Add a screenshot inside the iphone icon, or change
				text on the document icon. Just because vector icons are infinitely
				scalable doesn't mean they're infinitely legible. Iconic's three sizes
				aren't just scaled versions of the same icon, their detail adapts to
				their size in context.
			</p>
		</header>
		<section>
			<figure>
				<Image alt="Marketing slide" filename="slide-iconic-wall.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Search" filename="slide-iconic-search.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Icon page" filename="slide-iconic-page.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Guides" filename="slide-iconic-guides.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Payment" filename="slide-iconic-billing.png" />
			</figure>
		</section>
	</Layout>
)
